import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const search = {
  namespaced: true,
  state: {
    currentType: 'alarm',
    alarm: {
      criteria: {
        device: [],
        alarm: [],
        date: {
          startDateTime: null,
          endDateTime: null
        },
        triggers: []
      },
      alarms: [],
      result: [],
      export: [],
      keyword: '',
      playingResult: null,
      playingChannel: -1,
      isPlayingAll: false,
      isFinishedPlaying: false,
      currentPageIndex: 0,
      activeVideoId: -1,
      playList: [],
      MAX_PAGE_COUNT: 12,
    },
    bookmark: {
      criteria: {
        device: [],
        date: {
          startDateTime: null,
          endDateTime: null
        },
      },
      result: [],
      export: [],
      keyword: '',
      playingResult: null,
      playingChannel: -1,
      isPlayingAll: false,
      isFinishedPlaying: false,
      hasDateTimeError: false,
      currentPageIndex: 0,
      activeVideoId: -1,
      playList: [],
      MAX_PAGE_COUNT: 12,
    },
    vca: {
      criteria: {
        device: [],
        date: {
          startDateTime: null,
          endDateTime: null
        },
        vcaEventType: []
      },
      keyword: '',
      playingResult: null,
      playingChannel: -1,
      isPlayingAll: false,
      isFinishedPlaying: false,
      currentPageIndex: 0,
      MAX_PAGE_COUNT: 12,
      activeVideoId: -1,
      activeVCARule: {
        id: -1,
        data: null
      },
      playList: [],
      result: [],
      export: []
    },
    tmis: {
      criteria: {
        device: [],
        tmisEventType: [],
        date: {
          startDateTime: null,
          endDateTime: null
        },
      },
      keyword: '',
      currentPageIndex: 0,
      MAX_PAGE_COUNT: 10,
      result: [],
      export: []
    },
    exportDataList: [],
    status: 'initial', // initial,searched,failed,loading
    exportStatus: 'NORMAL'
  },
  getters,
  actions,
  mutations,
};

export default search;
